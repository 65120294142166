import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

export interface displayToastProps {
  title: string
  subtitle?: string | null
  link?: string | null
  download?: boolean
}

export function displayToast({ title, subtitle, link, download }: displayToastProps) {
  toast(
    <div className="ml-1 flex flex-col justify-start gap-x-3 align-middle">
      <h5 className="font-semibold text-defaultColor">{title}</h5>
      {subtitle && link && (
        <Link
          to={link || ''} // downloadUrl only exists if completed
          download={download || false}
          className="text-primary"
          data-testid="toast-link-btn"
        >
          {subtitle}
        </Link>
      )}
      {subtitle && !link && <span className="text-defaultColor">{subtitle}</span>}
    </div>
  )
}
