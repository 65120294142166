import { useState } from 'react'

interface UseTwoPanelDragProps {
  minLeftWidth?: number
  defaultLeftWidth?: number
}

export const useTwoPanelDrag = ({ minLeftWidth = 0, defaultLeftWidth = 50 }: UseTwoPanelDragProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const [leftWidth, setLeftWidth] = useState(defaultLeftWidth)

  const startDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDragging(true)
    document.addEventListener('mousemove', onDrag)
    document.addEventListener('mouseup', stopDrag)
  }

  const onDrag = (e: MouseEvent) => {
    const newWidth = (e.clientX / window.innerWidth) * 100
    const minWidthPercent = (minLeftWidth / window.innerWidth) * 100
    setLeftWidth(Math.max(newWidth, minWidthPercent))
  }

  const stopDrag = () => {
    setIsDragging(false)
    document.removeEventListener('mousemove', onDrag)
    document.removeEventListener('mouseup', stopDrag)
  }

  return { isDragging, leftWidth, startDrag }
}
