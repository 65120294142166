import { matchRoutes, useLocation } from 'react-router-dom'

import {
  buyListRoutes,
  catalogRoutes,
  inventoryRoutes,
  invoiceRoutes,
  myProductsRoutes,
  purchaseOrderRoutes,
  recipeRoutes,
  reportsRoutes,
  requisitionRoutes,
  supplierRoutes,
  systemSettingRoutes,
  usersRoutes,
} from '@/modules/shared/constants'

const useFindMatchPurchaserRoutes = () => {
  const location = useLocation()

  const allRoutes = [
    requisitionRoutes,
    purchaseOrderRoutes,
    invoiceRoutes,
    buyListRoutes,
    catalogRoutes,
    supplierRoutes,
    inventoryRoutes,
    systemSettingRoutes,
    myProductsRoutes,
    reportsRoutes,
    recipeRoutes,
    usersRoutes,
  ]

  const matchedRouteIndex = allRoutes.findIndex((route) => matchRoutes(route, location))

  const routeMatches = allRoutes.map((_, index) => index === matchedRouteIndex)

  return {
    requisitionRoutesMatch: routeMatches[0],
    purchaseOrdersRoutesMatch: routeMatches[1],
    invoiceRoutesMatch: routeMatches[2],
    buyListRoutesMatch: routeMatches[3],
    catalogRoutesMatch: routeMatches[4],
    supplierRoutesMatch: routeMatches[5],
    inventoryRoutesMatch: routeMatches[6],
    systemSettingRoutesMatch: routeMatches[7],
    myProductRoutesMatch: routeMatches[8],
    reportsRoutesMatch: routeMatches[9],
    recipeRoutesMatch: routeMatches[10],
    usersRoutesMatch: routeMatches[11],
    accordionDefaultKey: matchedRouteIndex !== -1 ? matchedRouteIndex : null,
  }
}

export default useFindMatchPurchaserRoutes
