import { useQuery } from '@apollo/client'

import { GetDepartmentsDocument } from '@/graphql/purchasing/generated/getDepartments.generated'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

interface UseGetDepartmentsProps {
  purchaserId?: number
}

export function useGetDepartments(args?: UseGetDepartmentsProps) {
  const { purchaserId } = args || {}
  const { currentOrganisation } = useSessionUser()
  const { data, networkStatus, error } = useQuery(GetDepartmentsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      purchaserId: purchaserId || currentOrganisation?.id,
    },
  })

  return { departments: data?.purchaser?.departments, loadingStates: checkNetworkStatus(networkStatus), error }
}
