import { useLayoutEffect, useState } from 'react'

import { CheckNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

/**
 * This is a special hook used to avoid ui flashing when the table preferences finish fetching and jump directly to fetch the actual data.
 * It is a bit hard to explain. For instance: stateA (table preferences loading) and stateB (data loading)
 * `<QueryResult loading={stateA}>.... {stateB && data.length === 0 && <EmptyState/>}<QueryResult/>`
 * After stateA is finished , stateB will be `true` which means we're fetching the actual data.
 * The time period from stateA transitioning to stateB is very short. During that time, the data is still empty so you'll see the empty state flashes a bit (you will just encounter it occasionally, not always)
 * @param tablePreferencesLoading fetching table preferences state
 * @param loadingStates fetching actual data states based on table preferences
 * @param dataLength data length to check for empty state
 * @returns Returns the empty state (Used to conditionally render empty state)
 */
export function useStopUIFlashingEmptyState(
  tablePreferencesLoading: boolean,
  loadingStates: CheckNetworkStatus,
  dataLength: number
) {
  const [showEmptyState, setShowEmptyState] = useState(false)
  // The solution is runs state updates synchronously using the `useLayoutEffect` hook.
  useLayoutEffect(() => {
    if (
      (!tablePreferencesLoading || !loadingStates.setVariablesLoading || !loadingStates.loading) &&
      dataLength === 0
    ) {
      setShowEmptyState(true)
    } else {
      setShowEmptyState(false)
    }
  }, [tablePreferencesLoading, loadingStates.setVariablesLoading, loadingStates.loading, dataLength])

  return showEmptyState
}
