import i18next from 'i18next'

import {
  FinanceExportExecutionStatusEnum,
  ReportExecutionStatusEnum,
} from '@/graphql/purchase-plus/generated/purchasePlus_graphql'

export const handleExecutionStatusEnumTranslation = (
  status: FinanceExportExecutionStatusEnum | ReportExecutionStatusEnum
) => {
  switch (status) {
    case FinanceExportExecutionStatusEnum.Completed:
      return i18next.t('general.completed', 'Completed')
    case FinanceExportExecutionStatusEnum.Failed:
      return i18next.t('general.failed', 'Failed')
    case FinanceExportExecutionStatusEnum.Processing:
      return i18next.t('general.processing', 'Processing')
    default:
      return ''
  }
}
