import { useQuery } from '@apollo/client'

import { GetAccountsDocument } from '@/graphql/purchasing/generated/getAccounts.generated'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'

interface UseGetAccountsProps {
  departmentId: number
}

export default function useGetAccounts({ departmentId }: UseGetAccountsProps) {
  const { data, networkStatus, refetch } = useQuery(GetAccountsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
    variables: { departmentId },
  })

  return { accounts: data?.purchaser?.accountsInTree, loadingStates: checkNetworkStatus(networkStatus), refetch }
}
