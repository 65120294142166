import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetAvailableProductsDocument } from '@/graphql/purchasing/generated/getAvailableProducts.generated'
import { Product, RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

interface UseGetAvailableProductsProps {
  itemsPerPage?: number
  searchValue?: string
  single?: boolean
  ownerId?: number
}

export const useGetAvailableProducts = ({
  itemsPerPage = 25,
  searchValue = '',
  single,
  ownerId,
}: UseGetAvailableProductsProps = {}) => {
  const { pricedCatalogueId, invoiceId, buyListId, requisitionId } = useParams<{
    pricedCatalogueId: string
    invoiceId: string
    buyListId: string
    requisitionId: string
  }>()

  const { currentOrganisation } = useSessionUser()

  const {
    searchValue: searchValueURLParam,
    categoryIds,
    supplierIds,
    supplierProductCode,
    purchaserProductCode,
    pPlusProductCode,
    hasQuotes,
    masterProduct,
    barcode,
  } = useWatchQueryParams()

  let catalogueId = 0
  if (pricedCatalogueId) {
    catalogueId = Number(pricedCatalogueId)
  } else if (buyListId) {
    catalogueId = Number(buyListId)
  }

  const { data, error, networkStatus, fetchMore, refetch } = useQuery(GetAvailableProductsDocument, {
    variables: {
      sortPrices: { property: 'unitPrice', direction: RansackDirection.Asc },
      invoiceId: invoiceId ? Number(invoiceId) : 0,
      requisitionId: requisitionId ? Number(requisitionId) : 0,
      catalogueId,
      searchText: searchValueURLParam || searchValue,
      categoryIds: categoryIds as number[],
      supplierIds: supplierIds as number[],
      supplierProductCode: supplierProductCode,
      purchaserProductCode: purchaserProductCode,
      productId: Number(pPlusProductCode),
      hasQuotes,
      barcode,
      master: masterProduct ? masterProduct : null,
      nextGenQuotes: !!currentOrganisation?.featureFlags?.useNextGenQuotes,
      first: itemsPerPage,
      sort: { property: 'created_at', direction: RansackDirection.Desc },
      single,
      ownerId,
    },
    notifyOnNetworkStatusChange: true,
    context: { uri: PURCHASING_GRAPHQL_API },
    fetchPolicy: 'cache-and-network',
  })

  const onFetchMoreProducts = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.availableProducts?.pageInfo.endCursor,
      },
    })
  }

  const availableProducts = extractEdges<Product>(data?.currentPurchaser?.availableProducts)

  return {
    availableProducts,
    error,
    onFetchMoreProducts,
    loadingStates: checkNetworkStatus(networkStatus),
    pageInfo: data?.currentPurchaser?.availableProducts?.pageInfo,
    refetch,
  }
}
