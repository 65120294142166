import { useLazyQuery } from '@apollo/client'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { GetReportExecutionsDocument } from '@/graphql/purchase-plus/generated/getReportExecutions.generated'
import { ReportExecutionStatusEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { ReportExecution } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { handleExecutionStatusEnumTranslation } from '@/modules/reports/utils/handleExecutionStatusEnumTranslation'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { PollingReportsAtom } from '@/modules/shared/states'
import { extractEdges } from '@/modules/shared/utils/extractEdges'

/**
 * This hook useBackgroundTasks monitors report statuses and provides notifications for completed reports.
 * It uses a query to poll the status of reports and display a notification to the user when a report is ready.
 */
export const useBackgroundReportPolling = () => {
  const { t } = useTranslation()

  // pollingReportsAtom is an atom where we store the reports currently being executed/pending.
  const [pollingReports, setPollingReports] = useAtom(PollingReportsAtom)

  // intervalId is used to hold a reference to the interval timer.
  const intervalId = useRef<NodeJS.Timeout | null>(null)

  const [loadReportExecutions, { data }] = useLazyQuery(GetReportExecutionsDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    context: { uri: PURCHASE_PLUS_GRAPHQL_API },
  })

  const handleSubtitleText = (reportStatus: ReportExecutionStatusEnum) => {
    if (reportStatus === ReportExecutionStatusEnum.Completed) {
      return t('general.download', 'Download')
    }
    return t('reports.goToReportsPage', 'Go To Reports')
  }

  const handleToastLink = (
    reportStatus: ReportExecutionStatusEnum,
    reportId?: string | null,
    downloadUrl?: string | null
  ) => {
    if (reportStatus === ReportExecutionStatusEnum.Completed) {
      return downloadUrl
    }
    return `/reports/${reportId}`
  }

  /**
   * checkReportStatus takes the first report of pollingReports, queries its status, and sends notification if it's completed.
   * It's wrapped in useCallback to prevent unnecessary re-renders.
   */
  const checkReportStatus = useCallback(() => {
    // If there are no pending reports, exit function.
    if (!pollingReports || pollingReports.length === 0) {
      // Clear the interval
      if (intervalId.current) clearInterval(intervalId.current)
      return
    }

    const { reportNumber, reportId } = pollingReports[0]

    // Otherwise, we initiate a query to load report executions, with the reportNumber as variable.
    loadReportExecutions({ variables: { reportNumber, reportId: Number(reportId) } })

    // Get the report execution
    const reportExecution = extractEdges<ReportExecution>(data?.report?.executions)[0]

    if (reportExecution) {
      const { status: executionStatus, downloadUrl } = reportExecution

      // If the report is no longer processing (meaning it's done), we display a toast notifying user.
      if (executionStatus !== ReportExecutionStatusEnum.Processing && executionStatus !== undefined) {
        displayToast({
          title: t('reports.reportReady', 'Report {{reportNumber}} {{reportStatus}}', {
            reportNumber: reportNumber,
            reportStatus: handleExecutionStatusEnumTranslation(executionStatus),
          }),
          subtitle: handleSubtitleText(executionStatus),
          link: handleToastLink(executionStatus, reportId, downloadUrl),
          download: executionStatus === ReportExecutionStatusEnum.Completed,
        })
        // After displaying the toast, we remove this report from the polling list.
        setPollingReports((prevState) => (prevState.length > 1 ? prevState.slice(1) : []))
      }
    }
  }, [setPollingReports, loadReportExecutions, data, pollingReports])

  // useEffect starts the polling process when the component is mounted and clears the interval when it's unmounted.
  useEffect(() => {
    intervalId.current = setInterval(checkReportStatus, 30 * 1000) // starts polling every 30 seconds

    const timeoutId = setTimeout(
      () => {
        // Clear the interval after 10 mins
        if (intervalId.current) clearInterval(intervalId.current)
      },
      10 * 60 * 1000
    ) // 10 minute in miliseconds

    return () => {
      if (intervalId.current) clearInterval(intervalId.current)
      clearTimeout(timeoutId)
    }
  }, [checkReportStatus])
}
