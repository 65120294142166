export enum QueryParameter {
  Search = 'search',
  Status = 'status',
  From = 'from',
  To = 'to',
  Enabled = 'enabled',
  Categories = 'categories',
  ParentCategories = 'parent-categories',
  Suppliers = 'suppliers',
  Departments = 'departments',
  Accounts = 'accounts',
  SupplierProductCode = 'supplier-product-code',
  PurchaserProductCode = 'purchaser-product-code',
  PPlusProductCode = 'p-plus-product-code',
  MatchedInvoice = 'matched-invoice',
  CompletedReceivingNote = 'completed-receiving-note',
  PeriodYear = 'period-year',
  PeriodMonth = 'period-month',
  BestBuy = 'best-buy',
  StockLocations = 'stock-locations',
  OriginStockLocation = 'origin-stock-location',
  DestinationStockLocation = 'destination-stock-location',
  CountStatus = 'count-status',
  HideZero = 'hide-zero',
  SelfManaged = 'self-managed',
  HasQuotes = 'has-quotes',
  MasterProduct = 'master-product',
  OpenHierarchy = 'open-hierarchy',
  Image = 'image',
  Catalogued = 'catalogued',
  Matched = 'matched',
  CountedItems = 'counted-items',
  RequisitionType = 'requisition-type',
  Destination = 'destination',
  Owner = 'owner',
  OrganisationUnit = 'organisation-unit',
  Preferred = 'preferred',
  Verified = 'verified',
  LegalEntity = 'legal-entity',
  Id = 'id',
  TradeRelationshipId = 'trade-relationship-id',
  ApSystemId = 'ap-system-id',
  PurchaserId = 'purchaser-id',
  SupplierId = 'supplier-id',
  OwnerId = 'owner-id',
  OwnerName = 'owner-name',
  Barcode = 'barcode',
  TransferTypes = 'transfer-types',
  CatalogFilter = 'catalog-filter',
  AlreadyReceived = 'already-received',
  PPlusCode = 'p-plus-code',
  OrganisationId = 'organisation-id',
}
