import { useTranslation } from 'react-i18next'

export default function useDate() {
  const { i18n } = useTranslation()

  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 12 }, (_, i) => currentYear + 1 - i)
  const months = Array.from({ length: 12 }, (_, i) => i + 1)

  const getMonthNameFromNumber = (monthNumber: number) => {
    const currentLocale = i18n.language
    return new Intl.DateTimeFormat(currentLocale, { month: 'long' }).format(new Date(0, monthNumber))
  }

  const monthNames = months.map((month) => getMonthNameFromNumber(month - 1))

  return {
    currentYear,
    years,
    months,
    getMonthNameFromNumber,
    monthNames,
  }
}
