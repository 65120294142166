import copy from 'copy-to-clipboard'
import { useCallback, useEffect, useState } from 'react'

interface UseCopyToClipboardOptions {
  /**
   * timeout delay (in ms) to switch back to initial state once copied.
   */
  timeout?: number

  /**
   * Set the desired MIME type
   */
  format?: string

  /**
   * `copyOptions` : https://github.com/sudodoki/copy-to-clipboard#api
   */
}

export function useCopyToClipboard({ timeout = 1500, ...copyOptions }: UseCopyToClipboardOptions = {}) {
  const [hasCopied, setHasCopied] = useState(false)

  const onCopy = useCallback((value: string) => setHasCopied(copy(value, copyOptions)), [copyOptions])

  useEffect(() => {
    let timeoutId: number | null = null
    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false)
      }, timeout)
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId)
      }
    }
  }, [timeout, hasCopied])

  return {
    onCopy,
    hasCopied,
  }
}
