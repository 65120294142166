import { matchRoutes, useLocation } from 'react-router-dom'

import { myProductsRoutes, purchaserRoutes, systemSettingRoutes, usersRoutes } from '@/modules/shared/constants'

const useFindMatchSupplierRoutes = () => {
  const location = useLocation()
  const allSupplierRoutes = [myProductsRoutes, purchaserRoutes, systemSettingRoutes, usersRoutes]
  const matchedRouteIndex = allSupplierRoutes.findIndex((route) => matchRoutes(route, location))
  const routeMatches = allSupplierRoutes.map((_, index) => index === matchedRouteIndex)

  return {
    myProductsRoutes: routeMatches[0],
    purchasersRoutes: routeMatches[1],
    systemSettingRoutes: routeMatches[2],
    usersRoutesMatch: routeMatches[3],
    accordionDefaultKey: matchedRouteIndex !== -1 ? matchedRouteIndex : null,
  }
}

export default useFindMatchSupplierRoutes
