import { useEffect, useState } from 'react'

import { BreakpointKey } from '@/modules/shared/types/BreakpointKey'

type BreakpointStates = Record<BreakpointKey, boolean>

export const defaultBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  twoXL: 1536,
  threeXL: 1860,
  fourXL: 2260,
  fiveXL: 2660,
}

export function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const breakpointStates: BreakpointStates = Object.entries(defaultBreakpoints).reduce((acc, [key, value]) => {
    acc[key as BreakpointKey] = width >= value
    return acc
  }, {} as BreakpointStates)

  const isLargeScreen = width >= defaultBreakpoints.lg

  return { isLargeScreen, width, ...breakpointStates }
}
