import { useQuery } from '@apollo/client'

import { GetCurrentPurchaserDocument } from '@/graphql/purchasing/generated/getCurrentPurchaser.generated'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import isPublicRoutes from '@/modules/shared/utils/isPublicRoutes'

export function useCurrentPurchaser() {
  const { data, loading, error } = useQuery(GetCurrentPurchaserDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    skip: isPublicRoutes(),
  })

  const isProductOwner = (id: number) => id === data?.currentPurchaser?.id
  return {
    currentPurchaser: data?.currentPurchaser,
    loading,
    error,
    isProductOwner,
  }
}
