import i18next from 'i18next'

import { LanguageEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'

export const getLanguageEnumMapping = (languageEnum: LanguageEnum | undefined): string => {
  switch (languageEnum) {
    case LanguageEnum.EnUs:
      return `English (US) - ${i18next.t('localeNames.englishUS', 'English (US)')}`
    case LanguageEnum.EnAu:
      return `English (UK) - ${i18next.t('localeNames.englishUK', 'English (UK)')}`
    case LanguageEnum.EsEs:
      return `Español (España) - ${i18next.t('localeNames.spanishSpain', 'Spanish (Spain)')}`
    case LanguageEnum.ThTh:
      return `ไทย (ประเทศไทย) - ${i18next.t('localeNames.thaiThai', 'Thai (Thailand)')}`
    case LanguageEnum.FrFr:
      return `Français (France) - ${i18next.t('localeNames.frenchFrance', 'French (France)')}`
    case LanguageEnum.JaJp:
      return `日本語 (日本) - ${i18next.t('localeNames.japaneseJapan', 'Japanese (Japan)')}`
    case LanguageEnum.ZhCn:
      return `简体中文 (中国) - ${i18next.t('localeNames.simplifiedChineseChina', 'Simplified Chinese (China)')}`
    case LanguageEnum.ZhTw:
      return `繁體中文 (台灣) - ${i18next.t('localeNames.traditionalChineseTaiwan', 'Traditional Chinese (Taiwan)')}`
    case LanguageEnum.ZhHk:
      return `繁體中文 (香港) - ${i18next.t('localeNames.traditionalChineseHongKong', 'Traditional Chinese (Hong Kong)')}`
    case LanguageEnum.ZhSg:
      return `简体中文 (新加坡) - ${i18next.t('localeNames.simplifiedChineseSingapore', 'Simplified Chinese (Singapore)')}`
    default:
      return `Unknown Language`
  }
}
