import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Delivery, PurchaseOrderStatusEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import { displayToast } from '@/modules/shared/components/toast/displayToast'

interface UseWatchPOUnsentEvents {
  sendEvents: Delivery[]
  poStatus?: PurchaseOrderStatusEnum | null
  poNumber?: string | null
  startPolling: (pollInterval: number) => void
  stopPolling: () => void
}

// This hook is used to poll and check for unsent events on the purchase order.
// A newly created event will be set to 'unsent' as its initial state.
// So we have to notify the user that the event has been changed to another state (it might take a few seconds to process).
export function useWatchPOUnsentEvents({
  sendEvents,
  startPolling,
  stopPolling,
  poStatus,
  poNumber,
}: UseWatchPOUnsentEvents) {
  const { t } = useTranslation()
  const pollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  // setNewSentEvents is used to store new events currently being processed (storing the sent event id)
  const [newSentEvents, setNewSentEvents] = useState<number[]>([])

  useEffect(() => {
    // Run this only when the purchase order has status of SENT.
    if (poStatus === PurchaseOrderStatusEnum.Sent) {
      if (newSentEvents.length > 0) {
        newSentEvents.forEach((eventId) => {
          const sendEvent = sendEvents.find((sendEvent) => sendEvent.id === eventId)
          if (sendEvent) {
            // If the event currently being processed has changed to another state, indicating that it has finished processing,
            // then notify the user about the completed send event.
            if (sendEvent.state === 'sent') {
              displayToast({
                title: t('purchaseOrders.purchaseOrder.sendEvents.successfullySent', '{{poNumber}} Successfully Sent', {
                  poNumber: poNumber,
                }),
              })
              // Remove the event which has been processed
              setNewSentEvents((prevState) => prevState.filter((eventId) => eventId !== sendEvent.id))
            }
          }
        })
      }

      // If there are unsent events in the list of send events, run the polling every 1 second.
      if (sendEvents.some((event) => event.state === 'unsent')) {
        startPolling(1000)
        if (!pollTimeoutRef.current) {
          // stop the polling after 3 mins
          pollTimeoutRef.current = setTimeout(() => {
            stopPolling()
            pollTimeoutRef.current = null
          }, 300000)
        }
      } else {
        // stop the polling when there are no more unsent events in the list of send events.
        stopPolling()
        if (pollTimeoutRef.current) {
          clearTimeout(pollTimeoutRef.current)
          pollTimeoutRef.current = null
        }
      }
    }
  }, [sendEvents, startPolling, stopPolling])

  return {
    setNewSentEvents,
    newSentEvents,
  }
}
