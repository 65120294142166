import { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AsyncFunction = () => Promise<any>

interface UseAsyncQueueProps {
  onAllCompleted: () => void
}

/**
 *  This hook is used to add async functions to the queue and execute them one at a time.
 */
export function useAsyncQueue(options?: UseAsyncQueueProps) {
  const { onAllCompleted } = options || {}
  const [queue, setQueue] = useState<AsyncFunction[]>([])
  const [isRunning, setIsRunning] = useState(false)
  const [nonEmptyQueue, setNonEmptyQueue] = useState(false)

  const addToAsyncQueue = (asyncFunc: AsyncFunction) => {
    setQueue((prevQueue) => [...prevQueue, asyncFunc])
    setNonEmptyQueue(true)
  }

  useEffect(() => {
    const runAsyncQueue = async () => {
      if (isRunning || queue.length === 0) return

      const asyncFunc = queue[0]
      setIsRunning(true)

      try {
        await asyncFunc()
      } catch (error) {
        console.error('Error in async function:', error)
      } finally {
        setQueue((prevQueue) => prevQueue.slice(1))
        setIsRunning(false)
      }
    }

    if (nonEmptyQueue && queue.length === 0 && onAllCompleted) {
      onAllCompleted()
    }

    runAsyncQueue()
  }, [isRunning, queue])

  return { addToAsyncQueue, isRunning }
}
