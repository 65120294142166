import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import AlertContext from '@/modules/shared/providers/AlertProvider'

export function useAlert() {
  const { t } = useTranslation()
  const alert = useContext(AlertContext)

  if (!alert) {
    throw new Error('useAlert() must be used within an AlertProvider')
  }

  const genericNotAuthorizedAlert = () => {
    alert.alert({
      type: 'error',
      title: t('general.notAuthorized', 'Not Authorized'),
      message: t(
        'alert.unauthorized.message',
        'Sorry but your user account does not have the correct permissions to access this feature. Please speak with an administrator at your organisation or contact our support team for assistance.'
      ),
      buttonText: t('general.okay', 'Okay'),
    })
  }

  return { alertDialog: alert.alert, genericNotAuthorizedAlert }
}
