import { useQuery } from '@apollo/client'

import { GetDashboardStatsDocument } from '@/graphql/purchase-plus/generated/getDashboardStats.generated'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'

export function useGetDashboardStats() {
  const { data, error, loading } = useQuery(GetDashboardStatsDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
  })

  return {
    dashboardStats: data?.dashboardStats,
    error,
    loading,
  }
}
